import React, { useState } from 'react';
import data from '@data/template-2/homepage.json';
import { graphql } from 'gatsby';
import Layout from '@components/commons/template-2/layout';
import SearchArea from '@containers/template-2/faq/search-area';

import FAQ from '@containers/template-2/homepage/faq';
import { getRandomFaqs } from '../../utils/getRandomFaqs';


const IndexPage = ({
    data: {
        allStrapiWebsites: { nodes: websites },
        allStrapiFaqs,
        AbstractionDataStore: { getUser, getBusiness, getTradePro }
    }
}) => {
    const appSyncData = {
        trade_pro: {
            email: getTradePro?.emails?.items.length ? getTradePro?.emails?.items[0]?.email : getUser?.email,
        },
        business: {
            phone: getBusiness?.phones?.items.length ? getBusiness?.phones?.items[0]?.phoneNumber : getUser?.phone,
            displayName: getBusiness?.displayName,
            shortDescription: getBusiness?.shortDescription,
            radiusServed: getBusiness?.radiusServed
        }
    };
    const nodes = [{
        ...websites[0],
        trade_pro: {
            ...websites[0].trade_pro,
            ...appSyncData.trade_pro
        },
        business: {
            ...websites[0].business,
            ...appSyncData.business
        }
    }];
    const businessData = nodes[0].business;
    const businessLayoutData = {
        logo: businessData.logoImage?.localFile?.childCloudinaryAsset?.fluid ?? null,
        phone: businessData.phone,
        email: nodes[0].trade_pro.email,
        singleLineAddress: businessData.address?.singleLineAddress,
        socialProfiles: businessData.socialProfiles,
        services: businessData.highlightedServices,
        ...businessData
    };
    const seoData = {
        title: businessData.displayName,
        description: businessData.shortDescription,
        domain: nodes[0].websiteDomain.name,
        logo: businessData.logoImage?.url ?? null,
        previewImage: businessData.coverImage?.url ?? null,
    };
    
    const faqData = {
        ...data.faq,
        faqs: getRandomFaqs(allStrapiFaqs.nodes) ,
    };

  

   
    const [keyWord,setKeyword] = useState("") 

   

    const handleKeyword = (value) =>{
        setKeyword(value)
    }
    

    return (
        <Layout
            style={{ background: '#f9f9fc' }}
            businessData={businessLayoutData}
            seoData={seoData}
            shadow={false}
            faqs={faqData}
        >
            <div style={{ maxWidth: '1366px' }} className="mx-auto">
                <SearchArea handleKeyword={handleKeyword}/>
             
               
                <FAQ
                    data={faqData}
                    keyword={keyWord}
                    className="px-5 md-1:px-10 xl:px-24 mt-400px mb-50px"
                    classNames={{ serviceHeading : 'mb-30px md-1:mb-50px'}}
                />
                
            </div>
        </Layout>
    );
};
export const query = graphql`
    query ($strapiId: Int!, $userId: ID!, $businessId: ID!, $tradeProId: ID!) {
        AbstractionDataStore {
            getUser(id: $userId) {
                email
                phone
            }
            getBusiness(id: $businessId) {
                id
                displayName
                shortDescription
                radiusServed
                phones {
                    items {
                      phoneNumber
                    }
                }
            }
            getTradePro(id: $tradeProId){
                id
                emails {
                    items {
                        email
                    }
                }
            }
        }
        allStrapiWebsites(filter: { strapiId: { eq: $strapiId } }) {
            nodes {
                websiteDomain {
                    name
                }
                trade_pro {
                    email
                }
                business {
                    logoImage {
                        url
                        localFile {
                            childCloudinaryAsset {
                                fluid(maxWidth: 200) {
                                    ...CloudinaryAssetFluid
                                }
                            }
                        }
                    }
                    phone
                    socialProfiles {
                        id
                        name
                        url
                    }
                    displayName
                    shortDescription
                    businessFeatures {
                        name
                    }
                    coverImage {
                        url
                        localFile {
                            childCloudinaryAsset {
                                fluid(maxWidth: 1100) {
                                    ...CloudinaryAssetFluid
                                }
                            }
                        }
                    }
                    highlightedServices {
                        text
                        title
                        photo {
                            url
                            localFile {
                                childCloudinaryAsset {
                                    fluid(maxWidth: 1400) {
                                        ...CloudinaryAssetFluid
                                    }
                                }
                            }
                        }
                    }
                    images {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 2000) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    process {
                        longDescription
                        shortDescription
                        title
                    }

                    feedbacks {
                        review
                        fullName
                        img {
                            url
                        }
                    }
                    radiusServed
                    address {
                        singleLineAddress
                        longitude
                        latitude
                    }
                }
            }
        }
        allStrapiFaqs{
            nodes {
              websites {
                id
              }
              question
              answer
            }
          }
    }
`;

export default IndexPage;
